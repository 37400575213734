import React from "react";
import { Link } from "gatsby";
import { Layout, Row, Col, BackTop, Button } from "antd";
import { Helmet } from "react-helmet";
import Header from "../../components/common/header";
import CommonFooter from "../../components/common/footer";
import UseCaseSider from "../../components/useCases/use-cases-sider";

import css from "../../styles/guide.css";

const UseCaseOfficeDiary = ({}) => (
  <div>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Office Diary - DayViewer</title>
      <meta
        name="description"
        content="Need an office diary? Have you tried DayViewer Online Planner yet?. Helps you plan time and tasks in a team - making it ideal as an office diary."
      />
    </Helmet>
    <Layout>
      <Header />
      <Layout style={{ minHeight: "100vh" }}>
        <BackTop />
        <UseCaseSider />
        <Layout className="guide-body">
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <h1>Online Office Diary</h1>
            <Button className="homepage-signup-btn">
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                Sign Up
              </a>
            </Button>
          </div>
          <Row>
            <Col>
              <h2>
                An Online Diary for your Office To Access Anytime To Check
                Schedules &amp; Events
              </h2>
              <p>
                Do you have so many things going on in your office, but nowhere
                centralized enough to keep everyone in the loop? DayViewer can
                be used as a shared online diary system, making sure everyone
                knows important company events and information.
              </p>
              <p>
                Calendar entries are the default entry type in DayViewer, no
                need to make them tasks, just drop in an event and location in
                your DayViewer Team Room for the best shared calendar
                experience.
              </p>
              <h3>Plan events and company functions with clarity</h3>
              <p>
                DayViewer has a number of views which makes it really easy to
                see what's coming up. Use timelines and tables to see schedules
                clearly. The dashboard is good for seeing what is coming up at a
                glance.
              </p>
              <h3>Internal online diary</h3>
              <p>
                Many offices may have a shared paper diary or a wall mounted
                calendar to book rooms and other shared resources. If you
                regularly need to book rooms or other resources for meetings,
                then we would suggest using the shared team calendar system to
                efficiently let everyone know what is booked and when.
              </p>
              <h3>Shared Online Notice Board</h3>
              <p>
                Create a DayViewer Board to make an events board or for other
                information that your office might need to refer to at any time.
                It makes sense to have everything together in one place, making
                virtual working so much easier.
              </p>

              <h3>
                Creating any calendar entry takes seconds - take a look at our{" "}
                <Link to="/tutorial-content/quick-start/">tutorial</Link> to see
                how.
              </h3>

              <p>
                See more details about Team Rooms in our{" "}
                <Link to={`/use-cases/team-calendar`}>
                  Team Calendar section
                </Link>
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Summary</h3>
              <p>
                Keep your office organized, know what is happening anytime in
                real time, get reminders about events and organize together.
              </p>
              <p>
                Trial DayViewer Team Rooms free for 14 days by{" "}
                <Button className="homepage-signup-btn">
                  <a
                    href="#"
                    onClick={() =>
                      window.open(
                        "https://app.dayviewer.com/auth/signup",
                        "_self"
                      )
                    }
                  >
                    Signing Up
                  </a>
                </Button>{" "}
                <Link to={`/plans/`}>(Team Room Pricing)</Link>,
              </p>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Online Office Diary</h3>
              <a
                href="#"
                onClick={() =>
                  window.open("https://app.dayviewer.com/auth/signup", "_self")
                }
              >
                <img
                  src="https://dayviewer.s3-us-west-2.amazonaws.com/img/home/dvnext/usecases/office-diary.png"
                  alt="online office diary"
                  title="DayViewer Office Diary"
                />
              </a>
            </Col>
          </Row>
          <Row>
            <Col>
              <h3>Similar Use Cases</h3>
              <Link to={`/use-cases/team-calendar`}>Team Calendar</Link>,
              <Link to={`/use-cases/work-scheduler`}>Work Scheduler</Link>
            </Col>
          </Row>
        </Layout>
      </Layout>
      <CommonFooter />
    </Layout>
  </div>
);

export default UseCaseOfficeDiary;
